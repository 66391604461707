<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-autocomplete :items="items" :disabled="disabled" :loading="loading" v-model="valueC" :search-input.sync="search"
      :label="name" item-text="name" item-value="uuid" autocomplete="off" clearable hide-details outlined dense>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name + ' '+ data.item.lastName"></v-list-item-title>
          <v-list-item-subtitle v-if="data.item.phone" class="text-capitalize"
            v-html="data.item.phone"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { mapGetters } from "vuex";
export default {
  name: "filter-by-customers",
  props: {
    selectedCustomer:String,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => "Search by Customers",
    },
  },
  data() {
    return {
      valueC: null,
      loading: false,
      items: [],
      search: null,
    };
  },

  watch: {
    selectedCustomer(val){
      if (val) {
        this.search=val.name
        this.valueC=val
      }
    },
    valueC(val) {
      if (val != null && val != undefined && val != "" && val.length > 4) {
        this.$emit("selectC", val);
      }
      if (val == null) {
        this.$emit("selectC", val);
      }
    },
    search(val) {
      if (val && val.length > 4) {
       
        this.getClients();
      } else if (val && val.length != 0 && val.length <= 4) {
        //
      } else {
        this.getClients();
      }
    },
  },
  methods: {
    getClients() {
      this.loading = true;
      let body = {
        
          limit: 100,
          offset: 0,
       
      };
      body =
        this.search != null && this.search != "" && this.search.length > 4
          ? { ...body, query: this.search }
          : body;

      if (this.search == null) {
        delete body.query;
      }
      getAPI.post("/customer/filterList", body).then((res) => {
        this.items = res.data.result;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>
<style lang=""></style>
